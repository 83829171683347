import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import CaseHeader from 'components/work/CaseHeader'
import ContentSection from 'components/work/ContentSection'
import WorkShowcase from 'components/work/WorkShowcase'
import Wrapper from 'components/Wrapper'
import Stats from 'components/Stats'
import MetaTags from 'components/MetaTags'
import Section from 'components/Section'
import Testimonial from 'components/Testimonial'
import Pill from 'components/Pill'
import PillGroup from 'components/Pill/PillGroup'
import Related from 'components/industries/Related'

const projectHandle = 'aldo'

const Aldo = () => (
  <>
    <MetaTags title="Aldo digital transformation | Custom software development in Montreal | Volume7" />

    <CaseHeader
      breadcrumbs={[
        {
          label: 'Work',
          link: '/work/',
        },
        {
          label: 'Aldo',
        },
      ]}
      client="Aldo"
      heading={INFO_FOR_PROJECT_NAME[projectHandle].heading}
      industry={INFO_FOR_PROJECT_NAME[projectHandle].industry}
      year={INFO_FOR_PROJECT_NAME[projectHandle].year}
      figure={INFO_FOR_PROJECT_NAME[projectHandle].figure}
    />

    <ContentSection
      title="The challenge"
      content={
        <>
          <p>
            Multinational fashion retailer Aldo was struggling to innovate
            within an increasingly digital world, making it difficult to keep up
            with younger, more nimble competitors. With limited technical
            know-how and existing resources focused on their core footwear
            business, collaborating with an external technical partner for new
            project initiatives became the obvious solution.
          </p>
          <p>
            Aldo selected us as their digital innovation partner of choice
            because of our extensive skill set and ability to deliver value out
            of fewer resources. We were entrusted with turning their multitude
            of pilot project ideas into reality, and doing so as quickly and
            efficiently as possible.
          </p>
        </>
      }
    />

    <ContentSection
      title="Our solution"
      content={
        <>
          <p>
            We assembled a dedicated team tasked with building a wide range of
            business-critical software, both consumer-facing and for use
            internally. By implementing a startup-like workflow and leveraging
            our{' '}
            <Link to="/services/development/#process">
              hybrid software development process
            </Link>
            , we were able to consistently deliver quality software more
            efficiently, gaining additional stakeholder buy-in with every
            successful product launch. We also opted for modern technology
            frameworks, creating a solid foundation on which Aldo would
            eventually build upon.
          </p>
          <ul>
            <li>
              Fully-integrated product reservation system built on AWS Lambda
            </li>
            <li>
              Internal web app used by Aldo employees to create and discover
              volunteering opportunities
            </li>
            <li>
              Mobile app used for in-store defect tracking by retail associates
              at over 500 stores
            </li>
            <li>
              Web service used to generate barcodes for Aldo's colossal SKU
              catalog
            </li>
          </ul>
          <PillGroup>
            <Pill label="Project management" link="/services/strategy/" />
            <Pill label="Analytics" link="/services/analytics/" />
            <Pill
              label="User experience (UX) design"
              link="/services/design/"
            />
            <Pill label="User interface (UI) design" link="/services/design/" />
            <Pill label="Web development" link="/services/development/" />
            <Pill label="Mobile development" link="/services/development/" />
            <Pill label="Backend development" link="/services/development/" />
            <Pill
              label="Digital transformation"
              link="/services/development/"
            />
            <Pill label="Cloud management" link="/services/development/" />
          </PillGroup>
        </>
      }
    />

    <Section flushTop>
      <Wrapper>
        <WorkShowcase
          photos={[
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/aldo/logit.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="In-store defect tracking mobile app"
                />
              ),
              large: true,
              caption: 'In-store defect tracking mobile app',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/aldo/store-pickup.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="In-store pickup built atop the product reservation system"
                />
              ),
              large: true,
              caption:
                'In-store pickup built atop the product reservation system',
            },
            {
              photo: (
                <StaticImage
                  src="../../assets/images/work/aldo/highfive.jpg"
                  layout="fullWidth"
                  placeholder="blurred"
                  alt="Internal web app for volunteering initiatives"
                />
              ),
              large: true,
              caption: 'Internal web app for volunteering initiatives',
            },
          ]}
        />
      </Wrapper>
    </Section>

    <ContentSection
      title="The results"
      content={
        <>
          <p>
            Our involvement with Aldo contributed to an increase in resource
            allocation for digital initiatives within the organization. This
            accelerated their digital transformation and allowed them to more
            effectively compete within the fast-changing world of retail. By
            infusing startup DNA into the company, Aldo is now better equipped
            to innovate rapidly and maintain their standing as a leader in the
            space.
          </p>
          <ul>
            <li>
              Significant stakeholder buy-in leading to an acceleration of
              Aldo's digital transformation
            </li>
            <li>
              Increase of <strong>250%</strong> in employee volunteering efforts
            </li>
            <li>
              Processed <strong>2.5 million</strong> product reservations and
              counting since launch in 2018
            </li>
            <li>
              Reduction of in-store technical issues by <strong>53%</strong>{' '}
              with proper defect tracking solution
            </li>
            <li>
              Observed a <strong>32%</strong> average increase in customer
              experience rating (North American stores)
            </li>
          </ul>
        </>
      }
      extra={
        <Stats
          stats={[
            {
              number: '2.5M',
              label: 'Product reservations since 2018',
            },
            {
              number: '53%',
              label: 'Reduction of in-store technical issues',
            },
            {
              number: '32%',
              label: 'Increase in retail customer experience rating',
            },
          ]}
        />
      }
    />

    <Section flushTop>
      <Wrapper size="narrow">
        <Testimonial
          name="Ysaël Pépin"
          role="IT Development Manager, Aldo"
          quote="We chose Volume7 as our digital innovation partner because of their multidisciplinary offering and ability to deliver quickly."
          alignment="left"
        />
      </Wrapper>
    </Section>
    <Related projects={['vape', 'usewalter']} hasBorder />
  </>
)

export default Aldo
